import { StaticImage } from "gatsby-plugin-image"
import * as React from "react"
import Layout from '../../../components/Layout/Layout'
import Therapist from "../../../components/Therapist/Therapist"

// markup
const name = 'Michelle VanDenHul'

const license = 'MSW, LCSW-PIP'
const areas = [
    'Registered Play Therapist',
    'Specializes in young children',
    'ADD/ADHD',
    'Trauma',
    'Behavioral Issues',
    'Grief',
    'Anxiety',
    'Depression',
    'Self-Esteem',
    'Divorce',
]

const IndexPage = () =>
(
    <Layout className="">
        <Therapist
            name={name}
            license={license}
            areas={areas}
            photo={(<StaticImage src='./michelle-vandenhul.jpg' alt={name} layout="constrained" className="shadow" />)}
            additionalLicense='Registered Play Therapist'
        >
        <p>
            Michelle VanDenHul is licensed in the State of South Dakota as a Certified Social Worker and is currently in Private Practice. She is a Qualified Mental Health Professional, Registered Play Therapist, and Registered Play Therapy Supervisor. Michelle received her Bachelors and Masters in Social Work from the University of North Dakota.
        </p>

        <p>
            Prior to beginning Private in 2009, Michelle spent three years as the Clinical Director of Children's Services for a mental health agency. Throughout her career, Michelle's focus has been working with children and families. She primarily serves children two to ten and works closely with the families and caretakers to meet the need of the children.
        </p>

        <p>
            Michelle works frequently with children who have experienced trauma through abuse and neglect, as well as children with behavioral, grief, anxiety, depression, self-esteem, divorce, and ADD/ADHD issues.
        </p>

        <p>
            Michelle is trained in play therapy and utilizes play therapy to help the client's she works with deal with a variety of issues. Play therapy allows children to communicate, express their thoughts and feelings, and solve problems through the work of play.
        </p>
        </Therapist>
    </Layout>
)

export default IndexPage
